import React, { Component } from 'react';
import Card from './CardUI';
import img1 from "../../images/showcases/web.png";
import img2 from "../../images/showcases/mobile.png";
import img3 from "../../images/showcases/system.png";
import imageNode from "../../images/showcases/nodejs.png";
import imageReact from "../../images/showcases/react.png";

import "./card-style.css";

class Cards extends Component {
    render() {
        return (
            <div className="c-cards">
                <div>
                    <div className="Montserrat-Bold">
                        <h1 className="header-title"> What we do <span className="blue">best</span> <br></br> for brands</h1>
                    </div>
                    <div className="c-services">
                        <div className="Montserrat-Bold c-card" style={{ paddingLeft: '0' }}>
                            <Card imgsrc={img1} title="Web Development"
                                content="We build websites, web systems, web platforms and various projects that allows different users to access through internet as a medium."
                            />
                        </div>
                        <div className="Montserrat-Bold c-card" style={{ padding: '10px' }}>
                            <Card imgsrc={img2}
                                content="Bring your business to another level by adding a mobile application in your stack. We build mobile apps for both B2C businesses or for internal usage."
                                title="Mobile Development" />
                        </div>
                        <div className="Montserrat-Bold c-card" style={{ paddingRight: '0' }}>
                            <Card imgsrc={img3} title="Systems"
                                content="Have a very specific system that your business require? We specialize in building very specific systems tailor made for your business process."
                            />
                        </div>
                    </div>
                </div>
                <div className="c-software-specialize">
                    <div className="Montserrat-Bold">
                        <h1 className="header-title-specialize"> Software we <br /> <span className="blue">specialize</span> with</h1>
                    </div>
                    <div className="c-specialize">
                        <div className="specialize">
                            <div className="c-specialize-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" viewBox="0 0 24 24"><path fill="currentColor" d="M20.597 7.482L24 16.518h-2.51l-.58-1.618h-3.246l.694-1.754h2.002l-.95-2.66l1.188-3.004zm-8.111 0l1.772 5.84l2.492-5.84h2.415l-3.643 9.036H13.14l-1.64-5.237l-1.72 5.237H7.404L6.17 14.402l1.214-3.742l1.342 2.661l1.903-5.839h1.857zm-8.746 0c1.064 0 1.872.305 2.424.917a2.647 2.647 0 0 1 .28.368L5.37 12.08l-.385 1.185c-.352.1-.753.151-1.204.151H2.293v3.102H0V7.482zm-.58 1.753h-.866v2.428h.86c.557 0 .94-.12 1.148-.358c.19-.215.284-.506.284-.873c0-.364-.107-.654-.323-.871c-.216-.217-.583-.326-1.103-.326z" /></svg>
                            </div>
                            <div className="c-specialize-detail">
                                <div className="c-specialize-title Montserrat-Bold">
                                    <h2>Web</h2>
                                </div>
                                <div className="c-specialize-description text-secondary Blinker">
                                    <p>Build modern websites or applications with web-app technologies </p>
                                </div>
                            </div>
                        </div>
                        <div className="specialize">
                            <div className="c-specialize-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" viewBox="0 0 24 24"><path fill="currentColor" d="M20.5 8.5v-3a1 1 0 0 0-1-1h-15a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1a1 1 0 0 0 1-1Zm-1 10h-15v-3h15Zm0-5h-15v-3h15Zm0-5h-15v-3h15Z" /><circle cx="6.25" cy="7" r=".75" fill="currentColor" /><circle cx="8.75" cy="7" r=".75" fill="currentColor" /><circle cx="6.25" cy="12" r=".75" fill="currentColor" /><circle cx="8.75" cy="12" r=".75" fill="currentColor" /><circle cx="6.25" cy="17" r=".75" fill="currentColor" /><circle cx="8.75" cy="17" r=".75" fill="currentColor" /></svg>
                            </div>
                            <div className="c-specialize-detail">
                                <div className="c-specialize-title Montserrat-Bold">
                                    <h2>Servers</h2>
                                </div>
                                <div className="c-specialize-description text-secondary Blinker">
                                    <p>We are able to suggest the most suitable stack for your business</p>
                                </div>
                            </div>
                        </div>

                        <div className="specialize">
                            <div className="c-specialize-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="auto" viewBox="0 0 20 20"><path fill="currentColor" d="M15 0a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h10Zm.6 15.388H4.4V18a.6.6 0 0 0 .6.6h10a.6.6 0 0 0 .6-.6v-2.612ZM10 16a1 1 0 1 1 0 2a1 1 0 0 1 0-2Zm5-14.6H5a.6.6 0 0 0-.6.6v11.988h11.2V2a.6.6 0 0 0-.6-.6Z" /></svg>
                            </div>
                            <div className="c-specialize-detail">
                                <div className="c-specialize-title Montserrat-Bold">
                                    <h2>Mobile Apps</h2>
                                </div>
                                <div className="c-specialize-description text-secondary Blinker">
                                    <p>Provide powerfull applications to ensure the best user experience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="specialize">
                            <div className="c-specialize-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="auto" viewBox="0 0 24 24"><path fill="currentColor" d="M7 7H5a2 2 0 0 0-2 2v8h2v-4h2v4h2V9a2 2 0 0 0-2-2m0 4H5V9h2m7-2h-4v10h2v-4h2a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2m0 4h-2V9h2m6 0v6h1v2h-4v-2h1V9h-1V7h4v2Z" /></svg>

                            </div>
                            <div className="c-specialize-detail">
                                <div className="c-specialize-title Montserrat-Bold">
                                    <h2>API Integration</h2>
                                </div>
                                <div className="c-specialize-description text-secondary Blinker">
                                    <p>Bring multiple systems together by connecting them via APIs.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default Cards;