import React, { useEffect, useRef, useState } from 'react';
import { homeObjOne } from './Data';
import { Card, Footer, InfoSection, Navbar, Pricing } from '../../components';
import './styles.css';
import { Divider } from '@material-ui/core';
import About from '../../components/About/AboutusUI';
import Services from '../../components/Service/Services';
import Brand from '../../components/Brand/Brands';
import GlobalStyle from '../../globalStyles';
import ScrollToTop from '../../components/ScrollToTop';
import Snow from './Snow';
function Home() {

  const elts = {
    text1: useRef(),
    text2: useRef()
  };
  const texts = [
    "Innovative",
    "Interactive",
    "Software"
  ];
  const morphTime = 1;
  const cooldownTime = 0.25;
  const [loaderFinish, setLoaderFinish] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);


  function doMorph() {
    morph -= cooldown;
    cooldown = 0;

    let fraction = morph / morphTime;

    if (fraction > 1) {
      cooldown = cooldownTime;
      fraction = 1;
    }

    setMorph(fraction);
  }

  // A lot of the magic happens here, this is what applies the blur filter to the text.
  function setMorph(fraction) {
    // fraction = Math.cos(fraction * Math.PI) / -2 + .5;


    elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
    elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

    fraction = 1 - fraction;
    elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
    elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];
  }

  function doCooldown() {

    morph = 0;

    elts.text2.style.filter = "";
    elts.text2.style.opacity = "100%";

    elts.text1.style.filter = "";
    elts.text1.style.opacity = "0%";
  }

  // Animation loop, which is called every frame.
  function animate() {
    requestAnimationFrame(animate);

    let newTime = new Date();
    let shouldIncrementIndex = cooldown > 0;
    let dt = (newTime - time) / 1000;
    time = newTime;

    cooldown -= dt;

    if (cooldown <= 0) {
      if (shouldIncrementIndex) {
        textIndex++;
      }

      console.log(textIndex);
      if (textIndex >= 5) {
        setTimeout(() => {
          setLoaderFinish(true);

          setTimeout(() => {
            setHideLoader(true);
          }, 2000);
        }, 500);

        return;
      }
      doMorph();
    } else {
      doCooldown();
    }
  }
  var textIndex = texts.length - 1;
  var time = new Date();
  var morph = 0;
  var cooldown = cooldownTime;

  useEffect(() => {
    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    animate();

  }, []);
  return (
    <>
      {!hideLoader && (
        <div className="loader_overlay">
          <div className={!loaderFinish ? "_bar_container" : "_bar_container end"}>
            <div className="_bar"></div>
            <div className="_bar"></div>
            <div className="_bar"></div>
            <div className="_bar"></div>
            <div className="_bar"></div>
            <div className="_bar"></div>
            <div className="_bar"></div>
            <div className="_bar"></div>
            <div className="_bar"></div>
          </div>
          <div id="container">
            <span id="text1" ref={(ref) => { elts.text1 = ref }}></span>
            <span id="text2" ref={(ref) => { elts.text2 = ref }}></span>
          </div>
          <svg id="filters">
            <defs>
              <filter id="threshold">
                <feColorMatrix in="SourceGraphic"
                  type="matrix"
                  values="1 0 0 0 0
                0 1 0 0 0
                0 0 1 0 0
                0 0 0 255 -140" />
              </filter>
            </defs>
          </svg>
        </div>
      )}

      <div className={hideLoader ? "home_content active" : "home_content"}>
        <div className="custom-container">
          <GlobalStyle />
          <ScrollToTop />
          <Navbar />
          <Divider variant="middle" />
        </div>
        <InfoSection {...homeObjOne} />

        <div className="custom-container">
          <About />
          <Divider variant="middle" />
          <Card />
          {/* <Divider variant="middle" />
        <Slick /> */}
          <Divider variant="middle" />
          <Services />
          <Divider variant="middle" />
          <Brand />
          <Divider variant="middle" />
        </div>


        <Pricing />
        <Footer />

      </div >
    </>
  );
}

export default Home;
