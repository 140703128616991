import React from 'react';
import { Button } from '../../globalStyles';
import { AiFillThunderbolt } from 'react-icons/ai';
import { GiBlackball, GiCrystalBars } from 'react-icons/gi';
import { GiCutDiamond, GiRock } from 'react-icons/gi';
import { GiFloatingCrystal } from 'react-icons/gi';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import "./pricing.css";
import icon1 from "../../images/icons.png";
// or
import { IconContext } from 'react-icons/lib';
import { Icon } from './Pricing.elements';
import Link from '@material-ui/core/Link';
import Label from './Pricing.elements';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingHeading2,
  PricingContainer,
  LinkSend,

} from './Pricing.elements';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0),
    },
  },
}));
function Inputs() {
  const classes = useStyles();

  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection style={{ marginTop: '100px' }}>
        <PricingWrapper className="custom-container">
          <PricingHeading className="pheading Montserrat-ExtraBold">
            Let's <span className="startblue">start</span> something completely new together
          </PricingHeading>
          <PricingHeading2 className="pheading2 Montserrat-SemiBoldItalic">
            Drop us a line, and we'll get in touch.
          </PricingHeading2>
          <PricingHeading2 className="pheading2 Montserrat-SemiBoldItalic">
            We'll see if we're a match and how we can help each other
          </PricingHeading2>

        </PricingWrapper>
        <br />
        <div className="custom-container">
          <PricingHeading2 className="pheading2 Montserrat-SemiBoldItalic">
            <a href="https://wa.me/60167780275" target='_blank'>
              <FaWhatsapp style={{
                height: 24
              }} ></FaWhatsapp>(+60)167780275
            </a>
          </PricingHeading2>
          <PricingHeading2 className="pheading2 Montserrat-SemiBoldItalic">
            <a href="mailto:yewyang@cysoft.co" target='_blank'>
              <FaEnvelope style={{
                height: 20
              }} />
              yewyang@cysoft.co</a>
          </PricingHeading2>
          {/* <form style={{fullwidth:'100%'}}>
        <Input placeholder="Drop us a mail" className="itext Montserrat-SemiBoldItalic">
      
        <LinkSend className="project">
              <p className="sub Blinker-SemiBold" > Send </p>
              <Icon src={icon1}/>
          </LinkSend> 
        </Input>
       </form> */}
        </div>
      </PricingSection>
    </IconContext.Provider>


  );
}

export default Inputs;