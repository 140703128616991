import styled from 'styled-components';

export const Slider= styled.div`
 width: 1600px;

`;

export const Icon= styled.img`
 
  padding-left:20px;
  padding-right:20px; 
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;

`;

export const Img= styled.img`
  width: 500px;
  padding-left:20px;
  padding-right:20px;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  margin-bottom:50px;
  @media screen and (max-width: 575px) {
    margin:20px 0;
    padding:0;
  }
`;




