export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'We build',
  headline: 'custom website, mobile apps and systems  ',
  imgStart: '',
  img: require('../../images/Mockup.png').default,
  alt: 'Credit Card',
  
};

