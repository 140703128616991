import React from 'react';
import GlobalStyle from './globalStyles';
import Home from './pages/HomePage/Home';
import Products from './pages/Products/Products';
import SignUp from './pages/SignUp/SignUp';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { Navbar, Pricing, Footer } from './components';
import Card from './components/Cads/Cards';
import Divider from '@material-ui/core/Divider';
import Slick from './components/Slick/Slideslick';
import Brand from './components/Brand/Brands';
import About from './components/About/Aboutus';
//import Spec from './components/Specialize/Specializes';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './css/common.css'
import Services from './components/Service/Services';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
      </Switch>
  
    </Router>
  );
}

export default App;
