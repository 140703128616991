import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import './Slideslick.css';
import {Img, Icon, P} from './Slideslick.elements';
import img1 from "../../images/showcases/project1.png";
import img2 from "../../images/showcases/project2.png";
import img3 from "../../images/showcases/project3.png";
import icon1 from "../../images/icons.png"  ;
export default class PauseOnHover extends Component {
    constructor(props) {
        super(props);
        this.state = {
          dots: true
        };  
      }

  render() {
    var settings = {
     
      arrows:false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 768, // width to change options
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ],
     
      afterChange: (currentSlide) => {
        console.log(currentSlide);
        this.setState({
          dots: (currentSlide !== 'yourDesiredSlide')
        });
      }
    };

    return (
      
      <div className="c-showcase">
        <div className="c-showcase-title">
          <h1 className="h1s Montserrat-Bold">Showcases</h1>
          <Link className="project">
              <p className="subtitle Blinker-SemiBold"> View All Projects</p>
              <Icon src={icon1}/>
          </Link> 
        </div>     
       
        <Slider {...settings}>
          <div>
          <Img src={img1} alt="showimg"/> 
          </div>
          <div>
          <Img src={img2} alt="showimg"/> 
          </div>
          <div>
          <Img src={img3} alt="showimg"/> 
          </div>
          <div>
          <Img src={img1} alt="showimg"/> 
          </div>
          <div>
          <Img src={img2} alt="showimg"/> 
          </div>
          <div>
          <Img src={img3} alt="showimg"/> 
          </div>
        </Slider>      
       </div>
     
    );
  }
}
