import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import './brands.css';
import { Img, Icon, P } from './Brands.elements';
import img1 from "../../images/brands/brands1.png";
import img2 from "../../images/brands/brands2.png";
import img3 from "../../images/brands/brands3.png";
import img4 from "../../images/brands/brands4.png";
import img5 from "../../images/brands/brands5.png";
import img6 from "../../images/brands/brands6.png";
import img7 from "../../images/brands/brands7.png";
import Typography from "@material-ui/core/Typography";
import icon1 from "../../images/icons.png";

export default class PauseOnHover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dots: true
    };
  }

  render() {
    var settings = {

      arrows: false,
      infinite: true,
      slidesToShow: 6.5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 4,
            rows: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
            rows: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
            rows: 2,
          }
        }
      ],
      afterChange: (currentSlide) => {
        console.log(currentSlide);
        this.setState({
          dots: (currentSlide !== 'yourDesiredSlide')
        });
      }
    };


    return (
      <div>

        <div className="row">
          <div className="col-md-4 col-lg-3 c-brand">
            <h1 className="h1c Montserrat-Bold">Brands we <span className="work"> worked</span> with</h1>
          </div>
          <div className="col-md-8 col-lg-9 c-slider brands_slider">
            <Slider {...settings}>
              <div className="_item">
                <Img src={'/images/logos/dash.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/brands.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/citizen.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/lab.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/mcd.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/mers.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/ntuc.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/pioneer.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/pohheng.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/pvp.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/shilla.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/toshiba.png'} alt="showimg" />
              </div>
              <div className="_item">
                <Img src={'/images/logos/wise.png'} alt="showimg" />
              </div>

            </Slider>
          </div>
        </div>

      </div>

    );
  }
}
