import styled from 'styled-components';
import { FaMagento } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container } from '../../globalStyles';
import './Footer.css';
export const FooterContainer = styled.div`
  background-color: blue;
  padding: 0rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center ;
  align-items: left;
  width:1600x;
`;

export const FooterSubscription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
`;

export const FooterSubHeading = styled.p`
  // font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  //   'Lucida Sans', Arial, sans-serif;
  // margin-bottom: 24px;
  // font-size: 24px;
`;

export const FooterSubText = styled.p`
  margin-bottom: 20px;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1600px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border: 1px solid #fff;

  &::placeholder {
    color: #242424;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

export const FooterLinksContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-content:right;
  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;
 
  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;
export const FooterLinkItems2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  text-align: left;
  width: 200px;
  box-sizing: border-box;
  color: #fff;
  

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;
export const FooterLinkItems3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 300px;
  box-sizing: border-box;
  color: #fff;
  margin-bottom:140px;
  

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;
export const FooterLinkTitle = styled.h2`
  margin-bottom: 16px;
  font-size:25px;
  
`;

export const FooterLink = styled(Link)`
  color: #DCDCDC;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size:12px;
  &:hover {
    color: #DCDCDC;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 100vw;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column-reverse;

  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 10;
  border: 0;
  width:120px;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
  margin-bottom:5px;
  // margin-left:50px;

`;
export const NavLogo = styled(Link)`
color: black;
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
font-size: 2rem;
display: flex;
align-items:center;
`;

export const Text = styled.p`
  color:#DCDCDC;
  font-size: 12px;
  margin-bottom: 10px;
  text-decoration: none;
  padding-right: 10;
  max-height: 500px;
  margin-bottom:20px;
  // margin-left:50px;
`;
export const SocialIcon = styled(FaMagento)`
  margin-right:10px;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 5px;
  margin-left:40px;
  
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
  // margin-left:50px;
`;

export const SocialIcons2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1px;
  margin-left:340px;
  
`;
export const SocialIcons3 = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 10px;
margin-left:50px;
`;
export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;

`;
