import styled from 'styled-components';

export const Icon= styled.img`
 
  padding-left:20px;
  padding-right:20px;
  border: 1;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;

`;
export const Img= styled.img`
  width: 100px;
  padding-left:20px;
  padding-right:20px;
  max-width: 100%;
  vertical-align: left;
  display: inline-block;
  margin-bottom:50px;
  

`;


