import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
//import { NavLink } from 'react-router-dom';
import { Button } from '../../globalStyles';
import { Nav, NavbarContainer, NavLogo, NavIcon, Img, MobileIcon, NavMenu, NavItem, NavLinks, NavItemBtn, NavBtnLink } from './Navbar.elements';
import img from '../../images/logo/CySoft_Black.svg';
import "./navbar.css";
const Navbar = () => {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    };
    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <div className='IconContact.Provuder'>
                <IconContext.Provider value={{ color: '#black' }}>
                    <Nav>
                        <NavbarContainer className="c-nav">
                            <NavLogo to="/">
                                <Img src={img} />
                            </NavLogo>
                            <MobileIcon onClick={handleClick}>
                                {click ? <FaTimes /> : <FaBars />}
                            </MobileIcon>
                            <NavMenu onClick={handleClick} click={click} className="Blinker">
                                {/* <NavItem>
                        <NavLinks to='/'>About us</NavLinks>
                    </NavItem>               */}
                                {/* <NavItem>
                        <NavLinks  to='/showcases'>Showcases</NavLinks>
                    </NavItem>               */}
                                <NavItemBtn>

                                    {/* <NavBtnLink href='https://wa.me/60167780275' target="_blank"> */}
                                    <Button
                                        onClick={() => {
                                            window.open("https://wa.me/60167780275", "_blank")
                                        }}
                                        className="c-contact-button">Contact Us</Button>
                                    {/* </NavBtnLink> */}

                                </NavItemBtn>
                            </NavMenu>
                        </NavbarContainer>
                    </Nav>
                </IconContext.Provider>
            </div>
        </>
    );
};

export default Navbar
