import { Divider } from "@material-ui/core";

const Services = () => {

    return (<>
        <div>

            <div className="row">
                <div className="col-md-6 col-lg-6 c-brand" style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                    <h1 className="h1c Montserrat-Bold"><span className="work">Web</span> Platforms</h1>
                    <p className="text-secondary" style={{
                        width: "100%"
                    }}>
                        We build more than just websites. We specialize in building web platforms for businesses that
                        perform most of their operations via the internet.
                    </p>
                    <br />
                    <p className="text-secondary" style={{
                        width: "100%"
                    }}>
                        We have built platforms that connects many different users together, such as multi-vendor marketplaces, food delivery platforms, e-bidding platforms and so on.
                    </p>
                </div>
                <div className="col-md-6 col-lg-6 c-slider">
                    <img style={{
                        width: "100%"
                    }} src="/images/7437896.png" />
                </div>
            </div>
            <Divider />
            <div className="row">

                <div className="col-md-6 col-lg-6 c-slider" >
                    <img style={{
                        width: "80%",
                        marginLeft: " 10%"
                    }} src="/images/systems.jpg" />
                </div>
                <div className="col-md-6 col-lg-6 c-brand" style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                    <h1 className="h1c Montserrat-Bold"><span className="work">Internal</span> Systems</h1>

                    <p className="text-secondary" style={{
                        width: "100%"
                    }}>
                        Do you have a very specific set of workspace requirements that can't be fulfilled with standard softwares?
                    </p>
                    <br />
                    <p className="text-secondary" style={{
                        width: "100%"
                    }}>
                        We interview, plan, brainstorm and develop software systems that are specifically catered for your workflow and also
                        the habits of your internal team.
                    </p>
                </div>
            </div>

            <Divider />
            <div className="row">
                <div className="col-md-6 col-lg-6 c-brand" style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                    <h1 className="h1c Montserrat-Bold"><span className="work">Cross Systems</span> Integration</h1>
                    <p className="text-secondary" style={{
                        width: "100%"
                    }}>
                        We are specialized in developing systems that are able to communicate with your other existing platforms or softwares.
                    </p>
                    <br />
                    <p className="text-secondary" style={{
                        width: "100%"
                    }}>
                        Systems that are developed are able to do API communication with all other components in your environment such as your CRM, Finance, Inventory or other ERPs.
                    </p>
                </div>
                <div className="col-md-6 col-lg-6 c-slider">
                    <img style={{
                        width: "100%"
                    }} src="/images/integration.jpg" />
                </div>
            </div>
        </div>

    </>)
};

export default Services;