import React from 'react';
import { Button } from '../../globalStyles';
import img from '../../images/logo/CySoft_White.svg';
import './Footer.css';

import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {
  NavLogo, 
  Img,
  Text,
  FooterContainer,
  FooterLinkItems,
  FooterLinkItems3,
  FooterLinkItems2,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIcons2,
  SocialIcons3,
  SocialIconLink,
 
} from './Footer.elements';

function Footer() {
  return (
    <div className="c-footer">
      <div className="custom-container">
        <div className="footer-top">
          <div className="c-social">
            <div>
              <Img src={img} />  
              <Text className="Blinker">Software Developing Studio</Text>  
            </div>
            <SocialIcons>
                {/* <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink href='/' target='_blank' aria-label='Instagram'>
                  <FaInstagram />
                </SocialIconLink> */}
            </SocialIcons>
          </div>
          <div className="c-contact">
                <FooterLinkTitle className="Montserrat-SemiBold">Contact Us</FooterLinkTitle>
                {/* <FooterLink className="Blinker">Chong</FooterLink> */}
                <FooterLink className="Blinker">+(60)16 778 0275</FooterLink>
                <FooterLink className="Blinker">yewyang@cysoft.co</FooterLink>
                <br></br>
                <FooterLinkTitle className="Montserrat-SemiBold">Office</FooterLinkTitle>
                <FooterLink className="Blinker">B21-04, Austin V Square, Jalan Austin Perdana 2/1, </FooterLink>
                <FooterLink className="Blinker">Taman Austin Perdana, 81100 Johor Bahru, Johor</FooterLink>          
          </div>
        </div>
        <div className="footer-bottom" >
          <div className="c-rights">
            CY Softwares- 2021©Copyright
          </div>
          <div className="c-policy Blinker-ExtraLight">
            {/* <div>Terms & Condition</div>
            <div className="policy">Privary Policy</div> */}
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Footer;
