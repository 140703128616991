import React from "react";
import"./Aboutus.css";

const About = props => {
    return (
      <div>
        <div className="list-body ">     
          <h2 className="list-title">{props.title}</h2>
          <h5 className="list">{props.text}</h5>  
          <p className="list-text">{props.lorem}</p>        
        </div> 
      </div>
    );
}

export default About;
