import styled from 'styled-components';
import "./infosection.css";

export const InfoSec = styled.div`
  color: #fff;
  padding: 10px 0;
  background: ${({ lightBg }) => (lightBg ? '#fff' : '#fff')};
`;

export const InfoRow = styled.div`
  display: flex;
  // margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
  @media screen and (max-width: 575px) {
    align-items:flex-start;
  }
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 70%;
  flex-basis: 30%;
  @media screen and (max-width: 600px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content:center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 600px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 600px) {
    padding-bottom: 65px;
  }
  
`;

export const ImgWrapper = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;

export const TopLine = styled.h1`
  color: ${({ lightTopLine }) => (lightTopLine ? '#101BF5' : '#4B59F7')};
  font-size: 90px;
  line-height: 16px;
  font-weight: bold;
  line-height: 1;  
  
`;

export const Img = styled.img`
  padding-right: 20;
  margin-left: 100px;
  border: 0;
  max-width: 100vw;
  size:auto;
 
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 76px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? 'black' : '#1c2237')};

`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};

`;
