import React,{Component} from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import About from'./AboutusUI';
import"./Aboutus.css";

class Aboutus extends Component{
    render(){
        return(
        <div>
            <div>
                <div className="list-border">
                    <div className="row c-row">
                        <div className="col-lg-12 col-xl-2">
                            <h2 className="Special-title Montserrat-ExtraBold">
                                <span>About</span> 
                                <span className="blue" style={{marginLeft:'15px'}}>Us</span> 
                            </h2>
                        </div> 
                        <div className="col-sm-12 col-md-6 col-xl-6">
                            <div className="c-team">                   
                                <div className="c-team-detail">
                                    <h2 className="team-number Montserrat-ExtraBold">2</h2>
                                    <h3 className="team-title">Core Team</h3>
                                </div>
                                <Divider orientation="vertical" flexItem  style={{margin:'20px 0'}}/> 
                                <div className="c-team-detail">
                                    <h2 className="team-number Montserrat-ExtraBold">20</h2>
                                    <h3 className="team-title">Team Members</h3>
                                </div>
                            </div>    
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4 Blinker">
                            <About lorem="We are a software development company that specializes in fully custom made software. Everything here is built from scratch according to your requirements."  />     
                        </div>                          
                    </div>  
                </div>                           
            </div>
            <br></br>
      
        </div>
        );
    }
}
export default Aboutus;