import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from '../../globalStyles';

import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img
} from './InfoSection.elements';
import "./infosection.css";

function InfoSection({
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  img,
  alt,
  imgStart,
  start
}) {
  
  return (
    <>
      <InfoSec lightBg={lightBg}>
          <InfoRow imgStart={imgStart} className="c-home">
            <div className="custom-container">
              <div className="textwrapper">
                <h1 className="title-bottom Montserrat-Bold">
                  <span className="title-top">{topLine}</span>
                  <br/>     
                  <span>{headline}</span>           
                </h1>                                                  
                <Img className="imgicon"></Img>
              </div>
            </div>
          </InfoRow>
      </InfoSec>
    </>
  );
}

export default InfoSection;
