import React from "react";
import "./card-style.css";

const Card = props => {
  return (

    <div className=" text-center">
      <div className="overflow">
        <img src={props.imgsrc} alt="Image 1" className="img services-img" />
      </div>
      <div className="card-body text-dark">
        <h4 className="card-title">{props.title}</h4>
        <p className="card-text text-secondary Blinker">
          {props.content}
        </p>

      </div>
    </div>

  );
}

export default Card;
